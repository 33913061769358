<template>
  <FormModal title="Wanneer is uw woning gebouwd?">
    <template #body>
      <PeriodOfConstructionFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import PeriodOfConstructionFormStep from '~/components/form/steps/periodOfConstruction/PeriodOfConstructionFormStep'

export default {
  components: {
    PeriodOfConstructionFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wanneer is uw woning gebouwd?',
      headDescription: 'Geef aan wanneer uw woning is gebouwd.',
      path: '/offertes-aanvragen/woning-gebouwd',
      progressValue: 15,
      checkoutStep: 1,
    }
  },
}
</script>
